export const STATIC_ROUTES = {
  landing: "/",
  garden: "/garden",
  sandbox: "/sandbox",
  sections: {
    portfolio: "portfolio",
    contact: "contact",
    about: "about"
  }
};
