export const portfolioItems = [
  {
    title: "Bestow",
    body: `
        I currently work for Bestow on various front end projects related to the enrollment of life insurance. 
        My current focus is enhancing our checkout codebase, separating it to its own micro-frontend and revamping it 
        to bring better reliability through better testing patterns, as well as enhancing observability and bringing in
        lots of insights about ours customer at checkout.
        I have been here for almost 2 years and been on a variety of different parts of the customer journey.
        It's kind of insane how much I've learned at my time here, but I guess that's just the result of being in a true "fast-paced" environment. 
        
        <br/>
        <br/>
        It's hard to sum up all the different things I've been responsible for or worked on in that time, 
        but some of the things of what I have accomplished or work on at Bestow are:
        <br/>
        <br/> - Implementing and design experiments for new product features
        <br/> - Design our automated test architecture + write all the tests (integration, e2e, unit, etc)
        <br/> - Write reusable components inside our design system used across the entire company
        <br/> - Revamped our design system to support two company rebrands, to the point where swapping themes is fully dynamic and only requires a single line change 😎
        <br/> - Set up Front End focused Guild Meetings and lead the discussions in them to talk about strategy and all things front end
        <br/> - Contribute to conversations about ways to improve design->dev workflows
        <br/> - Refactor, revamp and modernize React codebases to move away from anti-patterns and deprecated tools
        <br/> - Improve the observability of our tools and set up monitoring
        <br/> - Implementing and design experiments for new product features  
        `,
    projectType: "professional",
    img: {
      name: "bestow",
      filetype: "gif",
      count: 2
    },
    tech: [
      "React",
      "Launchdarkly (A/B Testing)",
      "New Relic (monitoring)",
      "Segment (analytics)",
      "Cypress",
      "React-Testing-Library",
      "Lots of React-specific libraries"
    ],
    links: [{ icon: "code", href: "https://github.com/DejayJD/DJ-Bot" }]
  },
  {
    title: "E-Commerce Distribution App",
    body: `
        Unable to provide photos due to the internal nature of the project.
        During my time consulting with Capco, we had a 6~ish month project revamping Interstate Batteries' retailer ordering app
        from being flash-based to a brand new Angular architecture.
        I played the role of the Senior Dev on the team (2 from our consulting company teamed up with some of the client developers)
        and making architectural decisions and designing the application.
        `,
    projectType: "professional",
    img: {
      name: "ib",
      filetype: "gif",
      count: 0
    },
    tech: ["Angular", "Reudx", "Zeppelin", "C#", ".NET", "Azure DevOps", "Git"],
    links: [{ icon: "code", href: "https://github.com/DejayJD/DJ-Bot" }]
  },
  {
    title: "Infrastructure Automation Platform",
    body: `
        Unable to provide photos due to the internal nature of the project.
        During my time consulting with Capco, I worked at Oncor on an automation platform that enabled both highly knowledgeable individuals and less knowledgeable personnel
        to manage various pieces of infrastructure orchestration and management in real-time. The app enabled users to create and customize Application Models that serve as a blueprint for an Application design within an environment, 
        provision servers automatically, kick off a variety of automation tools and scripts on live at the click of a button.
         <br><br>
        My role on the project was a Full Stack Developer. We organized our work by feature, and I would write the Angular UI code, the NodeJS service code,
         and the SQL Queries/Procedures, and occasionally write automation scripts for those services. During my time on the project,
         I revamped and redesigned the entire Angular app architecture, transformed our monolithic NodeJs backend into a simple microservice-oriented 
         environment using Docker and Docker-Compose, redesigned the permission schema, among other achievements.
        <br><br>
        This was probably the biggest and most ambitious project I've been a part of in my professional career and I learned a lot about 
        software development, design, software lifecycles, and automation.
        <br><br>
        In addition to this project, we also helped pioneer agile practices at the company and helped them land on a Kanban 
        environment and helped design their JIRA to automate their workflow processes. 
        `,
    projectType: "professional",
    img: {
      name: "ccd",
      filetype: "gif",
      count: 0
    },
    tech: [
      "Angular",
      "NodeJS",
      "Express",
      "Microservices",
      "Docker",
      "SQL",
      "Puppet",
      "Git",
      "Agile Kanban",
      "JIRA"
    ],
    links: [{ icon: "code", href: "https://github.com/DejayJD/DJ-Bot" }]
  },
  {
    title: "Custom Redux Framework",
    body: ` For the Interstate Batteries project, I developed a custom Redux middleware for Angular that is a mix of concepts from redux-thunk and Angular dependency injection.
            The reason we chose to go this route was because the existing app was pretty built entirely around the idea of using APIs to store everything.
            The previous app had no concept of client-side storage, so all state changes had to be managed via API calls.
            This led us to using redux with asynchronous patterns but a lot of the Redux concepts weren't perfect fits for Angular. 
            Additionally the client didn't want too much boilerplate code that would be difficult for a newer dev to sift through. 
            So what I ended up designing was an abstraction layer that made it easier to grasp to a developer unfamiliar with Redux and that fit a little better with Angular patterns.
            The main concept this framework brought to the table was auto generation of reducers/actions/stores to reduce boilerplate, and return everything as RxJS
            observables compatible with Angular.
            Additionally the entire framework is centered around the the idea of asynchronous actions traditionally managed by an Angular service layer that could be used in a similar fashion.
        `,
    img: {
      name: "redux",
      filetype: "gif",
      count: 3
    },
    tech: ["Angular", "Redux", "Javascript"],
    links: [
      { icon: "code", href: "https://github.com/DejayJD/base-redux-store" }
    ]
  },
  {
    title: "Slack DJ-Bot",
    body: `Ever wish you could digitally recreate that feeling of passing the aux around as you and your friends taking turns playing jams? 
                DJ-Bot for Slack is a bot plugin for slack that syncs up people in a slack channel's Spotify. People get to take turns picking out songs to play while everyone listens simultaneously.
                <br><br> This project was a fun side project that I'm still in active development on and hoping to soon get to the point of releasing it to the world.
                 The architecture is built entirely in NodeJS using Botkit to manage Slack communications and connecting to the Spotify API directly. 
                It also has a mongoDB database in the backend.`,
    img: {
      name: "djbot",
      filetype: "gif",
      count: 4
    },
    tech: [
      "NodeJS",
      "MongoDB",
      "Express",
      "Botkit",
      "Spotify API",
      "Slack API"
    ],
    links: [{ icon: "code", href: "https://github.com/DejayJD/DJ-Bot" }]
  },

  {
    title: "Augmented Reality Code Game",
    body: `Easily the most fun I've had on a side project in a while. 
        After getting inspired by escape rooms and some online Augmented Reality Games (ARGs), 
        I decided to bring the fun to my coworkers as well. I created a mock UNIX terminal on a pretend "server" with a programmed set of UNIX commands. 
        <br><br>
        My coworkers had to traverse and explore the "server" to uncover clues and secrets. I added easter egg after easter egg full of inside jokes.
        
        Then when I went on vacation, I unveiled the site to them for them to figure out while I was gone.
        The reward at the end was a dark themed skin of the app we had been working on at the time. 
        <br><br>
        I finished this project mostly in a weekend, but added some more clues/easter eggs as I kept coming up with ideas.
        `,
    img: {
      name: "darkpath",
      filetype: "gif",
      count: 2
    },
    tech: ["Javascript", "NodeJS", "jQuery"],
    links: [{ icon: "code", href: "https://github.com/DejayJD/a-dark-path" }]
  }
  // {
  //   id: "6",
  //   img: {
  //     name: "2048game",
  //     filetype: "gif",
  //     count: 1
  //   },
  //   title: "2048 Minimax AI",
  //   tech: ["Python", "PyGame"],
  //   date: "Fall 2015",
  //   links: [
  //     { icon: "code", href: "https://github.com/DejayJD/Python_2048_AI" }
  //   ],
  //   body: `
  //           This project had two parts to it: creating a playable 2048 clone, and then developing an AI to play it.
  //           To make the AI I used a Min-Max tree with AB Tree Pruning to calculate moves using a heuristic function that I developed.
  //           For heuristics I assigned points for the highest number tile and the number of empty spaces.
  //           This project is still in development and I am continuing to work on it to make it run more efficiently.
  //           <br><br>
  //           I first got interested in programming by playing around with hobby game development in middle school and high school.
  //           I still love finding opportunities to work with games whenever I can, so this project was both fun and a great
  //           opportunity to work with some AI algorithms`
  // },
  // {
  //   title: "Partitioning Networks using Genetic Algorithms",
  //   body:
  //     "<strong>Abstract</strong>: In this paper we devised partitions of a network using a genetic algorithm (GA) in order to minimize the mean global slowdown of packet flows throughout the network. Network performance has increasingly become a very integral portion of the quality of services provided over the Internet. Our proposed solution to achieving application-specific performance constraints is to partition networks according to the lengths of packet flows serviced at each routing device. We achieved these network partitions using a genetic algorithm implemented in Python. We compared the global mean slowdown of network performance for both unpartitioned networks alongside partitioned networks that were partitioned using our genetic algorithm and we found that networks partitioned with our genetic algorithm reduce the global mean slowdown of network performance with a variety of different packet distributions. We created a simulation in Python and the results show that partitioned networks have the potential to reduce the slowdown of network performance for well-defined packet distributions.",
  //   img: {
  //     name: "garouting",
  //     filetype: "gif",
  //     count: 2
  //   },
  //   tech: ["Python"],
  //   links: [
  //     { icon: "file-pdf-o", href: "downloads/router-partitioning-genetic.pdf" },
  //     {
  //       icon: "code",
  //       href: "https://github.com/DejayJD/CSE4344/tree/master/real_thing"
  //     }
  //   ]
  // },
  // {
  //   title: "Lunch Conductor",
  //   body: `
  //           Lunch Conductor is an app that coordinates lunches with your friends and those around you.
  //           Its a
  //           <br><br>
  //           This project initially started as a company hackathon project to help coordinate lunches between our coworkers.
  //           I had so much fun learning React Native and loved the idea so much that I continued to work and support it even after we finished.
  //           It also presented a great opportunity to get some hands on experience with
  //       `,
  //   img: {
  //     name: "lunchbro",
  //     filetype: "gif",
  //     count: 0
  //   },
  //   tech: ["React Native", "Redux"],
  //   links: [
  //     { icon: "code", href: "https://github.com/DejayJD/base-redux-store" }
  //   ]
  // }
];
