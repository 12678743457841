import { useEffect } from "react";
import {initLightSlider} from "./initLightSlider";

function portfolioRow(header, data) {
  return (
    <div>
      <strong> {header}</strong>: {data}
    </div>
  );
}

function renderTechUsed(block) {
  if (!block.tech) {
    return "";
  }
  return portfolioRow("Technology Used", block.tech.join(", "));
}

function renderLinks(block) {
  if (!block.links) {
    return "";
  }
  return "";
}

function renderImageSlider(block) {
  const iteratorArray = Array.apply(null, Array(block.img.count));
  return (
    <ul className="lightslide-ul" id="lightSliderImages">
      {iteratorArray.map((empty, i) => {
        const imgSrc = `/img/${block.img.name + (i + 1)}.${block.img.filetype}`;
        return (
          <li className="lightslide-li" data-thumb={imgSrc} data-src={imgSrc}>
            <img src={imgSrc} alt={block.img.name + i + 1} />
          </li>
        );
      })}
    </ul>
  );
}

export const PortfolioModal = ({ closeModal, portfolioBlock: block }) => {
  useEffect(() => {
    const handleEscapeKeypress = event => {
      if (event.keyCode === 27) closeModal();
    };
    window.addEventListener("keyup", handleEscapeKeypress, false);
    return () =>
      window.removeEventListener("keyup", handleEscapeKeypress, false);
  }, [closeModal]);
  useEffect(()=>{
    if (block) {
      initLightSlider(block);
    }
  }, [block])
  return (
    block && (
      <>
        <div className="row">
          <div className="col-sm-6">{renderImageSlider(block)}</div>
          <div className="col-sm-6">
            <h2>{block.title}</h2>
            {renderTechUsed(block)}
            {renderLinks(block)}
            <p
              className="modal-body"
              dangerouslySetInnerHTML={{ __html: block.body }}
            />
          </div>
        </div>
        <div rel="modal:close" className="modal-close" onClick={closeModal}>
          <svg
            enableBackground="new 0 0 26 26"
            version="1.1"
            viewBox="0 0 26 26"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.0605469,13L24.7802734,2.2802734c0.2929688-0.2929688,0.2929688-0.7675781,0-1.0605469  s-0.7675781-0.2929688-1.0605469,0L13,11.9394531L2.2802734,1.2197266c-0.2929688-0.2929688-0.7675781-0.2929688-1.0605469,0  s-0.2929688,0.7675781,0,1.0605469L11.9394531,13L1.2197266,23.7197266c-0.2929688,0.2929688-0.2929688,0.7675781,0,1.0605469  C1.3662109,24.9267578,1.5576172,25,1.75,25s0.3837891-0.0732422,0.5302734-0.2197266L13,14.0605469l10.7197266,10.7197266  C23.8662109,24.9267578,24.0576172,25,24.25,25s0.3837891-0.0732422,0.5302734-0.2197266  c0.2929688-0.2929688,0.2929688-0.7675781,0-1.0605469L14.0605469,13z"
              fill="#1D1D1B"
            />
          </svg>
        </div>
      </>
    )
  );
};
