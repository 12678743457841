import { Link } from "react-scroll";
import { NAVBAR_HEIGHT_INT } from "../constants";

export const SCROLL_OPTIONS = {
  duration: 800,
  smooth: "easeInOutQuint",
  offset: -1 * NAVBAR_HEIGHT_INT, // have to offset navbar size
};

export const ScrollLink = ({ className, to, children, ...rest }) => {
  return (
    <Link to={to} className={className} {...SCROLL_OPTIONS} {...rest}>
      {children}
    </Link>
  );
};
