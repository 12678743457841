import React from "react";
import { useParams } from "react-router-dom";

const storybookPaths = {
  "state-machines": "?path=/story/statemachines--state-machine-form"
};

export const SandboxPage = () => {
  const { route } = useParams();
  const queryParams = storybookPaths?.[route] || "";
  return (
    <div className="first-block">
      <iframe
        src={`/sandbox/dist/index.html${queryParams}`}
        scrolling="no"
        style={{ width: "100%", height: "100vh" }}
        title="storybook sandbox"
      />
    </div>
  );
};
