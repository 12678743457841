import { PLANT_TAGS, PlantTags } from "../components/PlantTags";
import {BLOG_TAGS, BlogTags} from "../components/BlogTags";
import { BlogBlock } from "../components/BlogBlock";
import blogEntriesRawJson from "../garden/garden-posts.json";
import { useCallback, useEffect, useMemo, useState } from "react";

const filterByBlogTags = (activeBlogTags, blogEntries) => {
  if (activeBlogTags.length === 0) {
    return blogEntries;
  }
  return blogEntries.filter(blogEntry => {
    return activeBlogTags.some(blogTag => {
      return blogEntry?.blogTags?.includes(blogTag);
    });
  });
};

const filterByPlantTags = (activePlantTags, blogEntries) => {
  if (activePlantTags.length === 0) {
    return blogEntries;
  }
  return blogEntries.filter(blogEntry =>
    activePlantTags.includes(blogEntry.plantTag.nameLower)
  );
};
const getDateTime = dateValue =>
  dateValue ? new Date(dateValue).getTime() : 0;

export const GardenPage = () => {
  const blogEntriesUnfiltered = useMemo(
    () =>
      blogEntriesRawJson.map(entry => ({
        ...entry,
        plantTag: PLANT_TAGS[entry.plantTag]
      })),
    []
  );
  const [activeFilters, setActiveFilters] = useState({
    blogTags: [],
    plantTags: []
  });
  const [filteredBlogEntries, setFilteredBlogEntries] = useState(
    blogEntriesUnfiltered
  );

  const handleFilter = useCallback(
    filterData => {
      const newActiveFilters = { ...activeFilters, ...filterData };
      setActiveFilters(newActiveFilters);
      const plantFilteredData = filterByPlantTags(
        newActiveFilters.plantTags,
        blogEntriesUnfiltered
      );
      const bothFilteredData = filterByBlogTags(
        newActiveFilters.blogTags,
        plantFilteredData
      );
      const filteredByDate = bothFilteredData.sort((prev, next) => {
        return getDateTime(next.date) - getDateTime(prev.date);
      });
      setFilteredBlogEntries(filteredByDate);
    },
    [activeFilters, blogEntriesUnfiltered]
  );

  useEffect(() => {
    handleFilter({});
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="first-block site-block blog-wrapper flex-center-h">
      <div className="blog-container">
        <h1 style={{ textAlign: "left" }}> My Digital Garden </h1>
        <h3 style={{ textAlign: "left" }}> Take a peek into my brain 🧠 </h3>
        <h5 style={{ maxWidth: "80ch" }}>
          I cultivate thoughts in my own interpretation of a{" "}
          <a href="https://joelhooks.com/digital-garden"> Digital Garden</a>.
          <br />
          <br />
          <div className="blog-description">
            My concepts:
            <br />{" "}
            <span style={{ color: PLANT_TAGS.seedling.color }}>
              Seedling 🌱{" "}
            </span>
            - Things that have recently caught my attention.
            <br />
            <span style={{ color: PLANT_TAGS.sapling.color }}>Sapling 🌿 </span>
            - Rough and loosely-held thoughts/opinions.
            <br />
            <span style={{ color: PLANT_TAGS.evergreen.color }}>
              Evergreen 🌲
            </span>{" "}
            - My tried and true opinions.
          </div>
        </h5>
        <div className="tags-container">
          <BlogTags
            tags={Object.keys(BLOG_TAGS)}
            clickable={true}
            onTagChange={data => {
              handleFilter({ blogTags: data });
            }}
          />
          <PlantTags
            onTagChange={data => {
              handleFilter({ plantTags: data });
            }}
          />
        </div>
        <div id="blog-content">
          {filteredBlogEntries.map(blogEntry => (
            <BlogBlock {...blogEntry} key={blogEntry.title} />
          ))}
        </div>
      </div>
    </div>
  );
};
