import { useState } from "react";

export const PLANT_TAGS = {
  seedling: {
    name: "Seedling",
    nameLower: "seedling",
    emoji: "🌱",
    color: "#41a545"
  },
  sapling: {
    name: "Sapling",
    nameLower: "sapling",
    emoji: "🌿",
    color: "#42821e"
  },
  evergreen: {
    name: "Evergreen",
    nameLower: "evergreen",
    emoji: "🌲",
    color: "#1a551f"
  }
};

export const PlantTags = ({ onTagChange }) => {
  const [activeTags, setActiveTags] = useState([]);
  const isTagActive = tagName => activeTags.includes(tagName);
  const handleTagClick = tagName => {
    let newTags = [];
    if (!activeTags.includes(tagName)) {
      newTags = [...activeTags, tagName];
      setActiveTags(newTags);
    } else {
      newTags = activeTags.filter(tag => tag !== tagName);
      setActiveTags(newTags);
    }
    if (onTagChange) {
      onTagChange(newTags);
    }
  };
  return (
    <div className="plant-tag-list">
      {Object.values(PLANT_TAGS).map(tag => (
        <button
          className={`plant-tag ${tag.nameLower} ${
            isTagActive(tag.nameLower) ? "active" : ""
          }`}
          key={tag.nameLower}
          onClick={() => {
            handleTagClick(tag.nameLower);
          }}
        >
          {tag.name} {tag.emoji}
        </button>
      ))}
    </div>
  );
};
