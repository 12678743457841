import { BlogTags } from "./BlogTags";
import { withRouter } from "react-router";
import { PlantTag } from "./PlantTag";

export const BlogBlock = withRouter(
  ({ date, blogTags, plantTag, title, history, route }) => (
    <div
      className="blog-block"
      onClick={() => {
        history.push(route);
      }}
    >
      {plantTag && (
        <PlantTag tag={plantTag} className="blog-block-plant-tags" />
      )}
      <h6 className="blog-date-header">{date}</h6>
      <h3 className="blog-title">{title}</h3>
      <div className="blog-block-tags">
        {blogTags && (
          <>
            <hr />
            <span>
              <BlogTags tags={blogTags || []} delimeter="," />
            </span>
          </>
        )}
      </div>
    </div>
  )
);
