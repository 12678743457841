import HeadshotImage from "../assets/headshot.png";
import ResumePDF from "../assets/JD-Francis-Resume.pdf";
import PDFIcon from "../assets/pdf.svg";
import { STATIC_ROUTES } from "../routes";
import { RouterLinkButton } from "../components/RouterLinkButton";
import { Link } from "react-router-dom";
import { ScrollLinkButton } from "../components/ScrollLinkButton";
import { PortfolioBlockGrid } from "../components/PortfolioBlockGrid";
import { useState } from "react";
import { PortfolioModal } from "../components/PortfolioModal";
import Modal from "react-modal";
import { CodeAnimationGIF } from "../components/CodeAnimationGIF";
import { useForm } from "react-hook-form";
import { MY_EMAIL } from "../constants";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Element } from "react-scroll";

export const LandingPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPortfolioBlock, setCurrentPortfolioBlock] = useState(null);
  const handleModalOpen = (block) => {
    setIsModalOpen(true);
    setCurrentPortfolioBlock(block);
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
    setCurrentPortfolioBlock(null);
  };
  const handleContactFormSubmit = (inputs) => {
    const mailtoLink = `mailto:${MY_EMAIL}?subject=${inputs.subject}&body=${inputs.body}\n\nSincerely,\n${inputs.name}`;
    window.location.href = encodeURI(mailtoLink);
  };
  const { register, handleSubmit } = useForm();
  return (
    <>
      <div className="first-block site-block landing-page-block flex-center">
        <div className="row h-100 w-100 bg-light-blue">
          <div
            className="col-sm-5 flex-center animation-wrapper"
            style={{ padding: 0 }}
          >
            <CodeAnimationGIF />
          </div>
          <div className="col-sm-7 flex-center bg-white" style={{ padding: 0 }}>
            <div className="landing-page-text">
              <h2>
                Hello, I'm
                <br />
                <span className="highlight-color" style={{ fontSize: "54px" }}>
                  JD Francis
                </span>
                <br />
                I write code <br />
                and curate thoughts
              </h2>
              <div style={{ marginTop: "4rem" }}>
                <ScrollLinkButton
                  className="btn btn-dark cta-button"
                  to={STATIC_ROUTES.sections.portfolio}
                >
                  My Work 🛠
                </ScrollLinkButton>
                <RouterLinkButton
                  className="btn btn-dark cta-button"
                  to={STATIC_ROUTES.garden}
                >
                  My Digital Garden 🌱
                </RouterLinkButton>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Element name="portfolio">
        <div className="site-block block-padding" id="portfolio">
          <h2 style={{ textAlign: "center" }}>Some of My Work 🛠</h2>
          <h5 style={{ textAlign: "center" }}>
            (My Resume):
            <a href={ResumePDF} target="_blank" rel="noreferrer">
              <img src={PDFIcon} alt="pdf icon" className="download-pdf" />
            </a>
          </h5>

          <div className="row" id="portfolio-grid-container">
            <PortfolioBlockGrid onPortfolioBlockClick={handleModalOpen} />
          </div>

          <Modal
            isOpen={isModalOpen}
            className="portfolio-modal"
            ariaHideApp={false}
          >
            <PortfolioModal
              closeModal={handleModalClose}
              portfolioBlock={currentPortfolioBlock}
            />
          </Modal>
        </div>
      </Element>

      <Element name="about">
        <div className="site-block bg-light-gray flex-center" id="about">
          <div className="row h-100 w-100">
            <div
              className="col-sm-8 flex-center flex-col"
              style={{ paddingLeft: "3rem" }}
            >
              <h2>Who Am I?</h2>
              <div className="about-text">
                <p style={{ maxWidth: "70ch" }}>
                  My name is JD Francis, I'm a skilled Front-End (FE) developer
                  working at{" "}
                  <a href="https://bestow.com" target="_blank" rel="noreferrer">
                    Bestow
                  </a>
                  . I've been all over the stack but I found a true passion in
                  the front end. I just love seeing my work come to life in
                  front of my eyes and I love seeing the impact on real people.
                  <br />
                  <br />
                  I'm a curious mind often deep in thought and I'm passionate
                  about creativity, both visual and technical design patterns,
                  and software architecture. I tend my thoughts like a garden,
                  and I use this site as a personal journal for them.
                  <br />
                  <Link to={STATIC_ROUTES.garden}>
                    See what's growing in my digital garden 🌱
                  </Link>
                </p>
                <div className="flex-center">
                  See my resume:
                  <a href={ResumePDF} target="_blank" rel="noreferrer">
                    <img
                      src={PDFIcon}
                      className="download-pdf"
                      alt="PDF Icon"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-sm-4 flex-center"
              style={{ paddingBottom: "50px", justifyContent: "flex-start" }}
            >
              <img
                src={HeadshotImage}
                className="headshot-img"
                alt="JD Francis Headshot"
              />
            </div>
          </div>
        </div>
      </Element>
      <Element name="contact">
        <div
          className="site-block contact-block bg-dark-offset flex-center"
          id="contact"
        >
          <h2>Contact Me </h2>
          <form
            className="contact-form-wrapper"
            id="mailform"
            onSubmit={handleSubmit(handleContactFormSubmit)}
          >
            <input
              ref={register}
              type="text"
              name="name"
              placeholder="Your Name"
            />
            <input
              ref={register}
              type="text"
              name="subject"
              placeholder="Whats it about?"
            />
            <textarea
              ref={register}
              name="body"
              placeholder="A message for me"
            />
            <div className="contact-button-wrapper">
              <button
                className="btn btn-light cta-button"
                id="mailbutton"
                style={{ padding: "12px" }}
              >
                SEND{" "}
                <FontAwesomeIcon
                  icon={faPaperPlane}
                  style={{ marginLeft: "2px" }}
                />
              </button>
            </div>
          </form>
        </div>
      </Element>
    </>
  );
};
