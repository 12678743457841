import blogEntries from "../garden/garden-posts.json";
import { render } from "react-dom";
import Zoom from "react-medium-image-zoom";
import ReactMarkdown from "react-markdown";
import React, { useEffect, useMemo } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coldarkDark as codeSyntaxTheme } from "react-syntax-highlighter/dist/esm/styles/prism";
import "charter-webfont/charter.css";
import { BlogTags } from "../components/BlogTags";
import { PlantTag } from "../components/PlantTag";
import {PLANT_TAGS} from "../components/PlantTags";

const renderers = {
  image: props => {
    const { src } = props;
    const fixedSrcPath = src.replace("../../public", "");
    const altText = src
      .split(/.*gardenImages\/(.*?)\.[png|gif|jpg|jpeg]/)[1]
      ?.replace(/-/g, " ");
    return (
      // Docs for Zoom: https://github.com/rpearce/image-zoom/tree/v3.0.15 - using v3 cause v4 didnt work well
      <Zoom
        wrapStyle={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
        zoomMargin={100}
        image={{
          src: fixedSrcPath,
          alt: altText,
          className: "img"
        }}
      />
    );
  },
  code: props => {
    const { language, value } = props;
    return (
      <SyntaxHighlighter
        language={language}
        style={codeSyntaxTheme}
        wrapLines={true}
        wrapLongLines={true}
      >
        {value}
      </SyntaxHighlighter>
    );
  }
};

export const GardenEntryPage = ({ route }) => {
  const blogEntry = useMemo(
    () => blogEntries.find(entry => entry.route === route),
    [route]
  );
  useEffect(() => {
    render(
      <ReactMarkdown source={blogEntry.content} renderers={renderers} />,
      document.getElementById("markdown-outlet")
    );
  }, [blogEntry]);

  return (
    <div className="first-block">
      <div className="blog-page-container">
        <div className="blog-header">
          <h1> {blogEntry.title} </h1>
          <hr />
          <div className="space-between" style={{ marginBottom: "0.5rem" }}>
            <BlogTags
              tags={blogEntry.blogTags}
              clickable={false}
              delimeter=","
              gap="7px"
            />
            <PlantTag tag={PLANT_TAGS[blogEntry.plantTag]} />
          </div>
          <div className="blog-header-date">
            <h6 className="blog-date-header"> {blogEntry.date} </h6>
          </div>
        </div>
        <div id="markdown-outlet"></div>
      </div>
    </div>
  );
};
