import { LandingPage } from "./pages/Landing.page";
import { Footer } from "./components/Footer";
import { Navbar } from "./components/Navbar";
import { GardenPage } from "./pages/Garden.page";
import "./styles/styles.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { STATIC_ROUTES } from "./routes";
import blogEntries from "./garden/garden-posts.json";
import { GardenEntryPage } from "./pages/GardenEntry.page";
import { SandboxPage } from "./pages/Sandbox.page";

function App() {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route exact path={STATIC_ROUTES.landing}>
          <LandingPage />
        </Route>
        <Route path={STATIC_ROUTES.garden}>
          <GardenPage />
        </Route>
        <Route path={`${STATIC_ROUTES.sandbox}/:route`}>
          <SandboxPage />
        </Route>
        <Route path={STATIC_ROUTES.sandbox}>
          <SandboxPage />
        </Route>
        {blogEntries.map(blogEntry => (
          <Route path={`/${blogEntry.route}`}>
            <GardenEntryPage route={blogEntry.route} />
          </Route>
        ))}
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
