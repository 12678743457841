import { scroller } from "react-scroll";
import { SCROLL_OPTIONS } from "./ScrollLink";

export const ScrollLinkButton = ({
  className,
  to,
  children,
  onClick,
  scrollOptions
}) => {
  return (
    <button
      className={className}
      onClick={event => {
        onClick && onClick(event);
        scroller.scrollTo(to, { ...SCROLL_OPTIONS, scrollOptions });
      }}
    >
      {children}
    </button>
  );
};
