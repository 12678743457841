import { portfolioItems } from "./portfolioData";

export const PortfolioBlockGrid = ({onPortfolioBlockClick}) =>
  portfolioItems.map((block, index) => (
    <div className="col-sm-4 portfolio-grid-wrapper animate1" onClick={()=>{onPortfolioBlockClick(block)}}>
      <div className="no-scroll portfolio-block">
        <div
          className="portfolio-grid-item inner-container"
          id={`portfolio${index}`}
        >
          <span className="portfolio-picture">
            <img
              src={`./img/${block.img.name}thumb.${block.img.filetype}`}
              alt={`${block.img.name} thumbnail`}
            />
          </span>
          <span className="sidebar green">
            <span>{block.title}</span>
          </span>
        </div>
      </div>
    </div>
  ));
