import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

export const BLOG_TAGS = {
  javascript: "Javascript",
  styling: "Styling",
  design: "Design",
  css: "CSS",
  react: "React",
  architecture: "Architecture",
  testing: "Testing",
  misc: "Misc"
};

export const BlogTags = ({
  tags = [],
  onTagChange,
  delimeter = "",
  clickable = false,
  gap = "6px"
}) => {
  const [activeTags, setActiveTags] = useState([]);
  const isTagActive = tagName => activeTags.includes(tagName);
  const handleTagClick = tagName => {
    let newTags = [];
    if (!activeTags.includes(tagName)) {
      newTags = [...activeTags, tagName];
      setActiveTags(newTags);
    } else {
      newTags = activeTags.filter(tag => tag !== tagName);
      setActiveTags(newTags);
    }
    if (onTagChange) {
      onTagChange(newTags);
    }
  };
  const iterator = tags.map(tag=>[tag, BLOG_TAGS[tag]]); // A 2D array of key/values. Aka -> [["javascript", "Javascript"]]
  return (
    <div className="blog-tags-container">
      <FontAwesomeIcon icon={faTags} color="#ba2829" />
      <ul className="blog-tag-list" style={{gap}}>
        {iterator.map(([tagKey, tagName], i) => (
          <li key={tagName}>
            <button
              className={`blog-tag-link ${
                isTagActive(tagKey) ? "active" : ""
              } ${clickable ? "clickable" : ""}`}
              onClick={() => clickable && handleTagClick(tagKey)}
            >
              {tagName}
              {i !== iterator.length - 1 ? delimeter : ""}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};
