import { Link as RouterLink, useLocation, withRouter } from "react-router-dom";
import { STATIC_ROUTES } from "../routes";
import { SCROLL_OPTIONS, ScrollLink } from "./ScrollLink";
import { scroller } from "react-scroll";

const handleOffLandingScroll = ({ location, history, to }) => {
  if (location.pathname !== "/") {
    history.push("/");
    setTimeout(() => {
      scroller.scrollTo(to, SCROLL_OPTIONS);
    }, 10);
  }
};

export const Navbar = withRouter(({ history }) => {
  const location = useLocation();
  return (
    <div className="navbar">
      <RouterLink to={STATIC_ROUTES.landing}>
        <div className="navbar-logo">JD</div>
      </RouterLink>
      <div className="navbar-list">
        <ul>
          <li>
            <ScrollLink
              className="navbar-link"
              to={STATIC_ROUTES.sections.portfolio}
              onClick={() => {
                handleOffLandingScroll({
                  location,
                  history,
                  to: STATIC_ROUTES.sections.portfolio,
                });
              }}
            >
              Portfolio
            </ScrollLink>
          </li>
          <li>
            <ScrollLink
              className="navbar-link"
              smooth={true}
              to={STATIC_ROUTES.sections.about}
              onClick={() => {
                handleOffLandingScroll({
                  location,
                  history,
                  to: STATIC_ROUTES.sections.about,
                });
              }}
            >
              About Me
            </ScrollLink>
          </li>
          <li>
            <RouterLink className="navbar-link" to={STATIC_ROUTES.garden}>
              Digital Garden
            </RouterLink>
          </li>
          <li>
            <RouterLink className="navbar-link" to={STATIC_ROUTES.sandbox}>
              Sandbox
            </RouterLink>
          </li>
          <li>
            <ScrollLink
              className="navbar-link"
              smooth={true}
              to={STATIC_ROUTES.sections.contact}
              onClick={() => {
                handleOffLandingScroll({
                  location,
                  history,
                  to: STATIC_ROUTES.sections.contact,
                });
              }}
            >
              Contact
            </ScrollLink>
          </li>
        </ul>
      </div>
    </div>
  );
});
